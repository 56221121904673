.navbar-nav{
	color: rgba(0, 0, 0, 0.74);
	font-size: 27px;
	font-family: 'Kumbh Sans', sans-serif;
}

.navbar-brand {
	font-family: 'Cedarville Cursive', cursive;
	font-size: 31px;
	padding: 15px 55px;
}

.customnav{
	justify-content: end;
}