/*~~~~~~~~~~~~~~~~~~~~~~~~~~~
~~~~~~~~ABOUT-SECTION~~~~~~~~
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.dash{
    border-style: dashed;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-color: #adc9f4;
    border-spacing: 90px;
}

.about{
	margin-top: 5vh;
}
.abthead{
	font-weight: 600;
	font-family: 'Caveat', cursive;
	margin-top: 20px;
	color:#6986b1;
	font-size: 7vh;
	padding-bottom: 1vh;
}

.txt{
	font-weight: 600;
	font-family: 'Caveat', cursive;
	font-size: 4vh;
	color:#496084;
	margin-left: 2px;
	margin-right: 2px;
	line-height: 1.0;
}

#img{
	height: 50vh;
	background: url('./KKyser.jpg') center center no-repeat;
	background-repeat: no-repeat;
    background-size: contain;
    /* max-width: 300px; */
	margin-top: 15vh;
}

.proficiencies{
	margin-top: 15vh;
	padding-bottom: 15vh;
	justify-content: space-between;
}

.prgcont{
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
}

.prg{
	max-width: 40vh;
	margin-top: 5vh;
	margin-bottom: 5vh;
	padding-top: 2vh;
	background-color: #FFEBFA;
}

.prghead{
	max-width: 75vh;
	background-color: #adc9f4;
	border-style: solid;
	border-color: #f5e4eb;
	padding: 3vh;
	border-radius: 25px;
	line-height: 1.0;
}


.left{
	text-align: end;
}

.middle{
	max-width: fit-content;
	align-items: center;
}

.right{
	text-align: left;
	border-radius: 25%;
}

.abtdmnd{
    width: 10vh;
    height: 10vh;
}

#resumelink{
	font-weight: 600;
	font-family: 'Caveat', cursive;
	font-size: 28px;
	color:#496084;
	margin-left: 2px;
	line-height: 1.0;
	text-decoration: underline;
}


#resumelink:hover{
	font-weight: 600;
	font-family: 'Caveat', cursive;
	font-size: 28px;
	color:#aa66c9;
	margin-left: 2px;
	margin-right: 2px;
	line-height: 1.0;
	text-decoration: underline;
}
