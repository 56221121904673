.carousel{
	margin: 2vh;
}
.dash{
    border-style: dashed;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-color: #adc9f4;
    border-spacing: 90px;
    padding-bottom: 45px;
}
.rowcont{
    padding-bottom: 10vh;
}

.row2{
    background-color: #adc9f4;
}
.projtitle{
    padding-top: 15vh;
    padding-bottom: 1vh;
    text-align: center;
    font-size: 5vh;
    font-family: 'Urbanist', sans-serif;
    color:#496084;
    /*cant decide between this color for text or this
    color: #271c70fd;*/
    
}

.projdesc{
    text-align: center;
}

.carouselcont{
    padding-top: 10vh;

}

.workimg{
    max-height: 700px;
    max-width: 1300px;
}

.unl{
    text-align: center;
    color: #496084;
    padding: 1vh;
}

.dmnd{
    width: 10vh;
    height: 10vh;
}

a {
	padding-right: 3vh;
    color: #496084;
}
