/*~~~~~~~~~~~~~~~~~~~~~~
~~~~~~contact form~~~~~~
~~~~~~~~~~~~~~~~~~~~~~~~*/
.dash{
    border-style: dashed;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-color: #adc9f4;
    border-spacing: 90px;
}
.blue{
	font-weight: 600;
	font-family: 'Caveat', cursive;
	font-size: 40px;
	color:#496084;
	margin-left: 1px;
	margin-right: 1px;
	line-height: 1.0;
}

.intro{
	font-weight: 200;
	font-family: 'Urbanist';
	font-size: 4vh;
	color:#496084;
}

.wrapper{
	margin-top: 100px;
}

.contactform{
	margin-top: 20px;
	margin-bottom: 115px;
}
.form-group{
	margin-bottom: 15px;
	padding-top: 10px;
}
.send{
	margin-top: 10px;
	background-color: #7b99c5;
	border-color: #496084;
}

.send:hover{
	background-color: #496084;
}

.text{
	margin-left: 7vh;
	margin-top: 3vh;
	max-width: 60vh;
	padding-top: 5vh;
}

.envelope{
	transform: scale(100%);
	color: "#FFFFFF";
	margin-left: 7px;
	margin-top: -2px;
}