/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
~~~~~~~~~FOOTER SECTION~~~~~~~~
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.footer{
	margin-top: 5vh;
	display: flex;
	justify-content: center;
	bottom: 0;
	width: 100%;
	text-align: center;
}
.ftr{
	border-style: solid;
	border-color: #00344a;
	border-left: none;
	border-top: none;
	border-bottom: none;
	width: 80%;
	margin-top: 1vh;
	font-size: 2vh;
	margin-bottom:1vh;
}

.icons{
	display: flex;
	flex-direction: row;
	width:20%;
}

.icons a{
	/* padding-left: 1vh; */
	color: #b06a92c1;
	align-items: center;
}